<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="4" xs="12">
        <!-- Componente para buscar por matricula  al modiciar el tipo de prestamos regresa el tipo de prestamos que pongas, solicitados o devueltos -->
        <BuscarPorMatricula
          :tiposDePrestamos="'prestados'"
          @cambiarVariables="changes()"
          @cambiarEstadoData="cambiarEstadoData"
          @prestamosCuentas="getPrestamosCuenta"
        ></BuscarPorMatricula>
        <!-- Componente para mostrar los datos de la cuenta que se encuentra -->
        <MostrarDatosCuenta
          :cuenta="cuenta"
          :tutor="tutor"
          :carrera="carrera"
          :existeCuenta="existeCuenta"
          @limpiarDatos="limpiarDatos()"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="overflow-y-auto"
        v-scroll:#scroll-target="onScroll"
        style="height: 800px"
      >
        <!-- Componente para mostrar los prestamos de la cuenta que se encontro y aceptar los prestamos -->
        <MaterialesPrestados
          :prestamos="this.prestamos"
          :existeCuenta="this.existeCuenta"
          :buscandoDatos="this.buscandoDatos"
          :cuenta="this.cuenta"
          @addPrestamo="addPrestamo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BuscarPorMatricula from "../component/BuscarPorMatricula";
import MostrarDatosCuenta from "../component/MostrarDatosCuenta";
import MaterialesPrestados from "../component/MaterialesPrestados";

export default {
  name: "devolucionesPrestamosIndividual",
  components: {
    BuscarPorMatricula,
    MostrarDatosCuenta,
    MaterialesPrestados,
  },
  data() {
    return {
      prestamos: [],
      cuenta: {},
      tutor: {},
      carrera: {},
      buscandoDatos: false,
      existeCuenta: true,
      offsetTop: 0,
    };
  },
  methods: {
    /**
     * Escucha el emit del MaterialesSolicitados para agregar o editar un prestamo que se acaba de agregar o modificar.
     */
    addPrestamo(prestamo) {
      let materialNuevo = this.prestamos.find(
        (itemD) => itemD.idPrestamo === prestamo.idPrestamo
      );

      if (materialNuevo !== undefined) {
        let index = this.prestamos.findIndex(
          (obj) => obj.idPrestamo == prestamo.idPrestamo
        );
        this.prestamos.splice(index, 1);
      }
      this.prestamos.push(prestamo);
    },
    /**
     * Metodo para comunicar la tabla y el componente de BuscarPorMatricula
     */
    changes() {
      this.buscandoDatos = true;
      this.prestamos = [];
      this.cuenta = this.tutor = this.carrera = {};
    },
    cambiarEstadoData() {
      this.buscandoDatos = false;
    },
    /**
     * Metodo para obtener los prestamos y la cuenta del componente BuscarPorMatricula
     * @param {json} prestamos un arrays de prestamos que se pasaran a la tabla
     * @param {json} cuenta es la cuenta que se encontro en el metodo obtenerCuenta del componente BuscarPorMatricula
     */
    getPrestamosCuenta(prestamos, cuenta) {
      this.prestamos = prestamos;
      this.cuenta = cuenta;
      this.tutor = cuenta.idTutor;
      this.carrera = cuenta.claveCarrera;
      this.existeCuenta = false;
      this.buscandoDatos = false;
    },

    /**
     * Se encarga de limpiar los datos principal se ejecuta a presionar el boton limpiar datos
     */
    limpiarDatos() {
      this.prestamos = [];
      this.cuenta = this.tutor = this.carrera = {};
      this.existeCuenta = true;
      this.matriculaABuscar = "";
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
  },
};
</script>

