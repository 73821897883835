<template>
  <v-card class="pa-1">
    <v-card-title>
      <div class="display-2 font-weight-light">Materiales Prestados</div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        class="ml-3"
      />
    </v-card-title>

    <v-responsive class="overflow-y-auto" max-height="calc(90vh - 350px)">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="prestamos"
        :items-per-page="10"
        :loading="buscandoDatos"
        :search="search"
        :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus'
      }"
        item-key="idPrestamo"
        class="elevation-2"
        show-select
        dense
        mobile-breakpoint
        no-data-text="No se Encuentran Préstamos"
      />
    </v-responsive>
    <!-- Modal Para editar el material seleccionado -->
    <modal-editar-material
      :dialog-material="dialgoEditarMaterial"
      :material="materialAEditar"
      @ocultar="ocultarModalEditar()"
      @editarPrestamos="addPrestamo"
    />

    <!-- Modal para aceptar los prestamos -->
    <v-col cols="12" class="text-right">
      <v-dialog v-model="dialog" persistent max-width="340">
        <template v-slot:activator="{ on }">
          <v-btn
            class="verdeBoton"
            :disabled="prestamos.length > 0 ? false : true"
            @click="elementosSeleccionados(on)"
          >Aceptar</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">¿Quiere aceptar todos los elementos seleccionados?</v-card-title>
          <v-card-text>Al dar aceptar se haran los cambios en el sistema indicando que el usuario acepta esos materiales.</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="verdeBoton darken-1"
              text
              @click="dialog = false"
              v-text="$t('cancelar')"
            />
            <v-btn
              color="verdeBoton darken-1"
              text
              @click="devolverPrestamos()"
              v-text="$t('aceptar')"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-card>
</template>

<script>
import ModalEditarMaterial from "../component/ModalEditarMaterial";
import ModalMaterial from "../component/ModalMaterial";

export default {
  name: "MaterialesSolicitados",
  components: {
    ModalMaterial,
    ModalEditarMaterial,
  },
  props: {
    prestamos: Array,
    existeCuenta: Boolean,
    buscandoDatos: Boolean,
    cuenta: Object,
  },
  data() {
    return {
      headers: [
        { text: "Material", value: "material" },
        { text: "Descripcion", value: "descripcion" },
        { text: "Potenciador", value: "potenciador" },
        { text: "Unidad De Medida", value: "unidadM" },
        {
          text: "Préstado",
          value: "fechaPedido",
          sortable: false,
          width: "20%",
        },
      ],
      selected: [],
      materialAEditar: {},
      search: "",
      materialSeleccionado: "",
      dialog: false,
      dialogMaterial: false,
      dialgoEditarMaterial: false,
      fixedHeader: false,
      cantidadMaterial: 1,
      height: 500,
    };
  },
  methods: {
    /**
     * Escucha el emit del ModalMaterial para agregar el prestamo que se acaba de agregar.
     */
    addPrestamo(prestamo) {
      this.$emit("addPrestamo", prestamo);
    },

    /**
     * Cambia el estado de todos los prestamos seleccionados a DEVUELTOS
     */
    devolverPrestamos() {
      if (this.selected.length > 0) {
        this.dialog = false;
        this.selected.forEach((prestamo) => {
          fetch(
            process.env.VUE_APP_URL_BACKEND + "/administrador/prestamos",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.state.cuenta.token,
              },
              body: JSON.stringify({
                idPrestamo: prestamo.idPrestamo,
                idCuenta: prestamo.idCuenta,
                descripcion: prestamo.descripcion,
                potenciador: prestamo.potenciador,
                estado: "DEVUELTO",
                updatedAt: Date.now(),
              }),
            }
          )
            .then((response) => {})
            .then(() => {
              const posicion = this.prestamos.indexOf(prestamo);
              this.prestamos.splice(posicion, 1);
            });
        });
        this.selected = [];
      }
    },

    /**
     * Se usa para validar que efectivamente se seleccionen elementos al precion el boton Aceptar
     * @on es el objeto que escucha al seleccionar un elmento
     */
    elementosSeleccionados(on) {
      if (this.selected.length > 0) {
        this.dialog = !this.dialog;
      } else {
        alert("Favor de seleccionar algun préstamo.");
      }
    },

    /**
     * Se encargara de poder editar las vairables del prestamo si es necesario, este se activa al dar la accion editar.
     * @item es el prestamo de la tabla principal el cual es un objeto de tipo material
     */
    editItem(item) {
      this.materialAEditar = item;
      this.dialgoEditarMaterial = !this.dialgoEditarMaterial;
    },

    /**
     * Escucha al evento del Modal para editar alguna prestamo
     */
    ocultarModalEditar() {
      this.dialgoEditarMaterial = !this.dialgoEditarMaterial;
    },

    /**
     * Se encarga de eliminar un prestamo de la base de datos, el cual el usuario elige.
     * @item es el prestamo de la tabla principal el cual es un objeto de tipo material
     */
    deleteItem(item) {
      fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/prestamos", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.cuenta.token,
        },
        body: JSON.stringify({
          idPrestamo: item.idPrestamo,
        }),
      }).then((response) => {
        const posicion = this.prestamos.indexOf(item);
        this.prestamos.splice(posicion, 1);
      });
    },
  },
};
</script>

<style>
#bodyBase {
  background-color: #fffcf7;
}

#bodyCol4 {
  height: 520px;
}
</style>
